import React, { useState } from "react";
import PropTypes from "prop-types";
import { closeModal } from "../../../store/actions/modal/modalActions";
import { useDispatch } from "react-redux";
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ModalTitle,
  SubmitButton,
} from "../Modal.styled";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../hooks/useIsMobile";
import { InputsContainer, ModalContainer, TextField } from "./SetFindingQestionImageDescriptionModal.styled";

const SetFindingQustionImageDescriptionModal = (props) => {
  const [description, setDescription] = useState(props?.description || "");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmit = () => {
    props?.onDescriptionSubmit(description);
    dispatch(closeModal());
  };

  return (
    <ModalContainer>
      <CloseButton onClick={handleCloseModal} />
      <ModalTitle>{t("findings.imageDescription.modalTitle")}</ModalTitle>
      <InputsContainer>
        <TextField
          label={t("findings.imageDescription.description")}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          isMobile={isMobile}
        />
      </InputsContainer>
      <ButtonsContainer>
        <CancelButton onClick={handleCloseModal}>
          {t("common.close")}
        </CancelButton>
        <SubmitButton onClick={handleSubmit} disabled={!description.trim()}>
          {t("common.add")}
        </SubmitButton>
      </ButtonsContainer>
    </ModalContainer>
  );
};

SetFindingQustionImageDescriptionModal.propTypes = {
  description: PropTypes.string,
  onDescriptionSubmit: PropTypes.func.isRequired,
};

export default SetFindingQustionImageDescriptionModal;
