import { JWT_REFRESH_TOKEN, JWT_TOKEN } from "../../constants/localStorage";
import { attachBeforeRequestListener } from "../../request/index";
import {
  authScopeStringGetHelper,
} from "../../util/helpers/authScopeHelpers";

//Interceptor unique name
export const accessTokensMiddlewareInterceptorName = "ACCESS_TOKEN_INTERCEPTOR";

export default () =>
  (next) =>
  (action) => {
    attachBeforeRequestListener((response) => {
      const jwtToken = authScopeStringGetHelper(JWT_TOKEN);
      const refresh = authScopeStringGetHelper(JWT_REFRESH_TOKEN);
      if (!jwtToken || !refresh) return Promise.resolve(response);
      if (response?.params?.noAuth) {
        delete response?.params?.noAuth;
        return Promise.resolve(response);
      }
      response.headers.Authorization = `Bearer ${jwtToken}`;
      return Promise.resolve(response);
    });
    next(action);
  };