import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../themes";
import { RedirectModalRendererContainer } from "../../../MeasuringInstruments/MeasuringInstrumentsTable/Renderers/RedirectModalRenderer/RedirectModalRenderer.styled";
import { UserRendererContainer } from "../../../Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer.styled";
import { hexToRGB } from "../../../../util/helpers/colorHelper";

export const TableDataContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props?.importTemplateTable &&
    css`
        height: 360px;
        overflow-y: auto;
    `}
`;

export const TableDataCellContainer = styled(Box)`
  display: flex;
  align-items: center;
  height: 72px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  ${(props) =>
    !props?.$isMobile &&
    css`
      border-top: 1px solid ${selectedTheme.colors.tableBorder};
      background-color: ${selectedTheme.colors.dashboardRowColor};
    `}
  @media (max-width: 600px) {
    height: initial;
    max-width: 100% !important;
    align-items: flex-start;
    justify-content: flex-start !important;
    & * {
      text-align: left;
      /* align-items: flex-start; */
      justify-content: flex-start;
    }
  }
`;

export const TableDataRowContainer = styled(Box)`
  display: flex;
  cursor: ${(props) => props?.$clickable && "pointer"};
  flex-direction: row;
  justify-content: space-between;
  background-color: ${selectedTheme.colors.dashboardRowColor};

  ${(props) =>
    props?.$isMobile &&
    css`
      border-top: 1px solid ${selectedTheme.colors.tableBorder};
    `}
  & ${UserRendererContainer} {
    color: ${selectedTheme.colors.pageTitleColor};
    cursor: pointer;
  }
  &:hover {
    ${(props) =>
      !props?.$clicked &&
      css`
        box-shadow: inset 4px 0px 0px ${selectedTheme.colors.tableBlueColor};
        background: ${selectedTheme.colors.tableRowSelectedBackgroundColor};
      `}
    ${(props) =>
      !props?.$isMobile &&
      !props?.$clicked &&
      css`
        background: ${selectedTheme.colors.tableRowSelectedBackgroundColor};
        box-shadow: inset 4px 0px 0px ${selectedTheme.colors.tableBlueColor};
        & ${TableDataCellContainer} {
          background: ${selectedTheme.colors.tableRowSelectedBackgroundColor};
        }
        > * {
          &:first-child {
            box-shadow: inset 4px 0px 0px ${selectedTheme.colors.tableBlueColor};
          }
        }
      `}
    ${(props) =>
      props?.$redirect &&
      css`
        & ${UserRendererContainer} {
          color: ${selectedTheme.colors.tableLink};
          cursor: pointer;
          text-decoration: underline;
          text-underline-position: under;
        }
        & ${RedirectModalRendererContainer} {
          color: ${selectedTheme.colors.tableLink};
          cursor: pointer;
          text-decoration: underline;
          text-underline-position: under;
        }
      `}
  }
  ${(props) =>
    props?.$isHovered &&
    !props?.$clicked &&
    css`
      background: ${selectedTheme.colors.tableRowSelectedBackgroundColor};
      ${(props) =>
        !props?.$isMobile &&
        css`
          background: ${selectedTheme.colors.tableRowSelectedBackgroundColor};
          & ${TableDataCellContainer} {
            background: ${selectedTheme.colors.tableRowSelectedBackgroundColor};
          }
        `}
      ${(props) =>
        props?.$redirect &&
        css`
          & ${UserRendererContainer} {
            color: ${selectedTheme.colors.tableLink};
            cursor: pointer;
            text-decoration: underline;
            text-underline-position: under;
          }
          & ${RedirectModalRendererContainer} {
            color: ${selectedTheme.colors.tableLink};
            cursor: pointer;
            text-decoration: underline;
            text-underline-position: under;
          }
        `}
    `}

  ${(props) =>
    props?.$selected &&
    props?.$redirect &&
    css`
      box-shadow: inset 4px 0px 0px ${selectedTheme.colors.tableBlueColor};
      background: ${selectedTheme.colors.tableRowSelectedBackgroundColor};
      & ${UserRendererContainer} {
        color: ${selectedTheme.colors.tableLink};
        cursor: pointer;
        text-decoration: underline;
        text-underline-position: under;
      }
      & ${RedirectModalRendererContainer} {
        color: ${selectedTheme.colors.tableLink};
        cursor: pointer;
        text-decoration: underline;
        text-underline-position: under;
      }
    `}
    
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 4px;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
  }
  ${(props) =>
    props?.$clicked &&
    css`
      background-color: ${hexToRGB(selectedTheme.colors.orange, 0.3)};
      & > div {
        background-color: ${hexToRGB(selectedTheme.colors.orange, 0.3)};
      }
    `}
`;
