import React, { useEffect } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import LastChanges from "../../../../components/LastChanges/LastChanges";
import NewScanningObjectContent from "../../../../components/ScanningObjects/NewScanningObject/NewScanningObjectContent";
import { MANAGER_ROLE } from "../../../../constants/rolesConstants";
import useAuth from "../../../../hooks/useAuth";
import { SINGLE_SCANNING_OBJECT_SCOPE } from "../../../../store/actions/scanningObjects/scanningObjectsActionConstants";
import {
  clearSingleScanningObject,
  fetchEditScanningObject,
  fetchSingleScanningObject,
} from "../../../../store/actions/scanningObjects/scanningObjectsActions";
import { selectIsLoadingByActionType } from "../../../../store/selectors/loadingSelectors";
import { selectSingleScanningObject } from "../../../../store/selectors/scanningObjectsSelectors";
import { formatDateTime } from "../../../../util/helpers/dateHelpers";
import { EditScanningObjectPageContainer } from "./EditScanningObjectPage.styled";

const EditScaningObjectPage = () => {
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();
  const prefilledData = useSelector(selectSingleScanningObject);
  const isLoading = useSelector(
    selectIsLoadingByActionType(SINGLE_SCANNING_OBJECT_SCOPE)
  );
  const { hasRole } = useAuth();

  const readOnlyPage = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  useEffect(() => {
    const scanningObjectId = routeMatch.params?.scanningObjectId;
    if (scanningObjectId) {
      dispatch(fetchSingleScanningObject({ scanningObjectId }));
    }
  
    return () => {
      dispatch(clearSingleScanningObject());
    };
  }, [routeMatch.params?.scanningObjectId, dispatch]);
  

  const editScanningObjectDispatchFunction = (data) => {
    return fetchEditScanningObject({
      scanningObjectId: routeMatch.params?.scanningObjectId,
      ...data,
    });
  };

  return (
    <EditScanningObjectPageContainer>
      <NewScanningObjectContent
        prefilledData={prefilledData}
        title={t("scanningObjects.editScanningObject.title")}
        submitButtonText={t("scanningObjects.editScanningObject.submitButton")}
        dispatchFunction={editScanningObjectDispatchFunction}
        isEditing={true}
        isLoading={isLoading ? true : false}
        readOnly={readOnlyPage}
      />
      {isLoading ? (
        <></>
      ) : (
        <LastChanges
          created={{
            date: formatDateTime(
              prefilledData?.createdAtUtc || new Date().toString()
            ),
            author: `${prefilledData?.createdByUser?.firstName || ""} ${
              prefilledData?.createdByUser?.lastName || ""
            }`,
          }}
          lastChanges={{
            date: formatDateTime(
              prefilledData?.updatedAtUtc || new Date().toString()
            ),
            author: `${prefilledData?.updatedByUser?.firstName || ""} ${
              prefilledData?.updatedByUser?.lastName || ""
            }`,
          }}
        />
      )}
    </EditScanningObjectPageContainer>
  );
};

export default EditScaningObjectPage;
