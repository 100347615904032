import {
  SCANNING_OBJECTS_ERROR,
  SCANNING_OBJECTS_FETCH,
  SCANNING_OBJECTS_SUCCESS,
  SCANNING_OBJECTS_SET,
  SINGLE_SCANNING_OBJECT_ERROR,
  SINGLE_SCANNING_OBJECT_FETCH,
  SINGLE_SCANNING_OBJECT_SUCCESS,
  SINGLE_SCANNING_OBJECT_SET,
  SCANNING_OBJECTS_CREATE_ERROR,
  SCANNING_OBJECTS_CREATE_FETCH,
  SCANNING_OBJECTS_CREATE_SUCCESS,
  SCANNING_OBJECTS_EDIT_ERROR,
  SCANNING_OBJECTS_EDIT_FETCH,
  SCANNING_OBJECTS_EDIT_SUCCESS,
  SCANNING_OBJECTS_CLEAR,
  SCANNING_OBJECTS_SET_TOTAL,
  SINGLE_SCANNING_OBJECT_CLEAR,
  SINGLE_SCANNING_OBJECT_CHECKLIST_TEMPLATES_SET,
  SINGLE_SCANNING_OBJECT_SECTIONS_SET,
  SINGLE_SCANNING_OBJECT_SINGLE_SECTION_SET,
  SINGLE_SCANNING_OBJECT_SECTION_QUESTIONS_SET,
  SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_SET,
  SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_SET,
  SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_ADD,
  SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_EDIT,
  SINGLE_SCANNING_OBJECT_SINGLE_SECTION_ADD,
  SINGLE_SCANNING_OBJECT_SINGLE_SECTION_EDIT,
  SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_EDIT,
  SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_ADD,
  SINGLE_SCANNING_OBJECT_SINGLE_SECTION_DELETE,
  SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_DELETE,
  SINGLE_SCANNING_OBJECT_INSTRUMENTS_ADD,
  SINGLE_SCANNING_OBJECT_INSTRUMENTS_DELETE,
  SINGLE_SCANNING_OBJECT_SINGLE_SECTION_MOVE,
  SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_MOVE,
  SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_TOGGLE,
  SCANNING_OBJECTS_DELETE_FETCH,
  SCANNING_OBJECTS_DELETE_SUCCESS,
  SCANNING_OBJECTS_DELETE_ERROR,
  SINGLE_SCANNING_OBJECT_STANDARDS_DELETE,
  SINGLE_SCANNING_OBJECT_STANDARDS_ADD,
  SINGLE_SCANNING_OBJECT_DEFAULT_STANDARDS_ADD,
  SCANNING_OBJECTS_SET_CONCLUSION,
  SCANNING_OBJECTS_CHANGED_SET,
  SINGLE_QUESTION_MOVE_ON_DRAG_AND_DROP,
  SCANNING_OBJECTS_COPY_FETCH,
  SCANNING_OBJECTS_COPY_SUCCESS,
  SCANNING_OBJECTS_COPY_ERROR,
  SINGLE_SCANNING_OBJECT_TYPE_SET,
  SCANNING_OBJECTS_FOR_FILTER_FETCH,
  SCANNING_OBJECTS_FOR_FILTER_SUCCESS,
  SCANNING_OBJECTS_FOR_FILTER_ERROR,
  SCANNING_OBJECTS_FOR_FILTER_SET,
  SCANNING_OBJECTS_FOR_FILTER_SET_TOTAL,
} from "./scanningObjectsActionConstants";

export const fetchScanningObjects = (payload) => ({
  type: SCANNING_OBJECTS_FETCH,
  payload,
});

export const fetchScanningObjectsForFilter = (payload) => ({
  type: SCANNING_OBJECTS_FOR_FILTER_FETCH,
  payload,
});
export const fetchScanningObjectsForFilterSuccess = (payload) => ({
  type: SCANNING_OBJECTS_FOR_FILTER_SUCCESS,
  payload,
});
export const fetchScanningObjectsForFilterError = (payload) => ({
  type: SCANNING_OBJECTS_FOR_FILTER_ERROR,
  payload,
});
export const fetchScanningObjectsSuccess = (payload) => ({
  type: SCANNING_OBJECTS_SUCCESS,
  payload,
});
export const fetchScanningObjectsError = (payload) => ({
  type: SCANNING_OBJECTS_ERROR,
  payload,
});
export const fetchCreateScanningObject = (payload) => ({
  type: SCANNING_OBJECTS_CREATE_FETCH,
  payload,
});
export const fetchCreateScanningObjectSuccess = (payload) => ({
  type: SCANNING_OBJECTS_CREATE_SUCCESS,
  payload,
});
export const fetchCreateScanningObjectError = (payload) => ({
  type: SCANNING_OBJECTS_CREATE_ERROR,
  payload,
});
export const fetchEditScanningObject = (payload) => ({
  type: SCANNING_OBJECTS_EDIT_FETCH,
  payload,
});
export const fetchEditScanningObjectSuccess = (payload) => ({
  type: SCANNING_OBJECTS_EDIT_SUCCESS,
  payload,
});
export const fetchEditScanningObjectError = (payload) => ({
  type: SCANNING_OBJECTS_EDIT_ERROR,
  payload,
});

export const fetchDeleteScanningObject = (payload) => ({
  type: SCANNING_OBJECTS_DELETE_FETCH,
  payload,
});
export const fetchDeleteScanningObjectSuccess = (payload) => ({
  type: SCANNING_OBJECTS_DELETE_SUCCESS,
  payload,
});
export const fetchDeleteScanningObjectError = (payload) => ({
  type: SCANNING_OBJECTS_DELETE_ERROR,
  payload,
});

export const fetchSingleScanningObject = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_FETCH,
  payload,
});
export const fetchSingleScanningObjectSuccess = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SUCCESS,
  payload,
});
export const fetchSingleScanningObjectError = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_ERROR,
  payload,
});
export const setScanningObjects = (payload) => ({
  type: SCANNING_OBJECTS_SET,
  payload,
});
export const setScanningObjectsForFilter = (payload) => ({
  type: SCANNING_OBJECTS_FOR_FILTER_SET,
  payload,
});
export const setScanningObjectsTotal = (payload) => ({
  type: SCANNING_OBJECTS_SET_TOTAL,
  payload,
});
export const setScanningObjectsForFilterTotal = (payload) => ({
  type: SCANNING_OBJECTS_FOR_FILTER_SET_TOTAL,
  payload,
});
export const clearScanningObjects = () => ({
  type: SCANNING_OBJECTS_CLEAR,
});
export const clearSingleScanningObject = () => ({
  type: SINGLE_SCANNING_OBJECT_CLEAR,
});

export const setSingleScanningObject = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SET,
  payload,
});

export const setSingleScanningObjectType = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_TYPE_SET,
  payload,
});

export const setScanningObjectChecklistTemplates = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_CHECKLIST_TEMPLATES_SET,
  payload,
});

export const setScanningObjectSelectedChecklistTemplate = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_SET,
  payload,
});
export const addScanningObjectSelectedChecklistTemplate = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_ADD,
  payload,
});
export const editScanningObjectSelectedChecklistTemplate = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_EDIT,
  payload,
});

export const toggleScanningObjectSelectedChecklistTemplate = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_TOGGLE,
  payload,
});

export const setScanningObjectSections = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SECTIONS_SET,
  payload,
});

export const addScanningObjectInstruments = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_INSTRUMENTS_ADD,
  payload,
});
export const deleteScanningObjectInstruments = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_INSTRUMENTS_DELETE,
  payload,
});

export const addScanningObjectStandards = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_STANDARDS_ADD,
  payload,
});
export const addScanningObjectDefaultStandards = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_DEFAULT_STANDARDS_ADD,
  payload,
});
export const deleteScanningObjectStandards = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_STANDARDS_DELETE,
  payload,
});

export const setScanningObjectSingleSection = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SINGLE_SECTION_SET,
  payload,
});
export const addScanningObjectSingleSection = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SINGLE_SECTION_ADD,
  payload,
});
export const editScanningObjectSingleSection = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SINGLE_SECTION_EDIT,
  payload,
});
export const deleteScanningObjectSingleSection = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SINGLE_SECTION_DELETE,
  payload,
});
export const moveScanningObjectSingleSection = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SINGLE_SECTION_MOVE,
  payload,
});

export const setScanningObjectSectionQuestions = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SECTION_QUESTIONS_SET,
  payload,
});

export const setScanningObjectSectionSingleQuestion = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_SET,
  payload,
});
export const addScanningObjectSectionSingleQuestion = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_ADD,
  payload,
});
export const editScanningObjectSectionSingleQuestion = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_EDIT,
  payload,
});
export const deleteScanningObjectSectionSingleQuestion = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_DELETE,
  payload,
});
export const moveScanningObjectSectionSingleQuestion = (payload) => ({
  type: SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_MOVE,
  payload,
});

export const moveSingleQuestionOnDragAndDrop = (payload) => ({
  type: SINGLE_QUESTION_MOVE_ON_DRAG_AND_DROP,
  payload,
});

export const setScanningObjectsConclusion = (payload) => ({
  type: SCANNING_OBJECTS_SET_CONCLUSION,
  payload,
});

export const setScanningObjectsChanged = (payload) => ({
  type: SCANNING_OBJECTS_CHANGED_SET,
  payload,
});

export const fetchCopyScanningObject = (payload) => ({
  type: SCANNING_OBJECTS_COPY_FETCH,
  payload,
});
export const fetchCopyScanningObjectSuccess = (payload) => ({
  type: SCANNING_OBJECTS_COPY_SUCCESS,
  payload,
});
export const fetchCopyScanningObjectError = (payload) => ({
  type: SCANNING_OBJECTS_COPY_ERROR,
  payload,
});
