import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Description,
  DescriptionEditorContainer,
  DescriptionOverlay,
  EditIcon,
  IconButton,
  ToolsContainer,
  Tooltip,
  TrashIcon,
} from "./DescriptionEditor.styled";

const DescriptionEditor = (props) => {
  const { t } = useTranslation();
  const [isEditingMode, setIsEditingMode] = useState(props?.isEditing);

  const handleDescriptionClick = () => {
    setIsEditingMode(!isEditingMode);
  };

  return (
    <DescriptionEditorContainer onClick={handleDescriptionClick}>
      <Description>{props?.imageDescription}</Description>
      {isEditingMode && <DescriptionOverlay $hidden={false} />}
      {isEditingMode && (
        <ToolsContainer>
          <Tooltip title={t("findings.imageDescription.changeDescription")}>
            <IconButton onClick={props?.onEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("findings.imageDescription.deleteDescription")}>
            <IconButton onClick={props?.onDelete}>
              <TrashIcon />
            </IconButton>
          </Tooltip>
        </ToolsContainer>
      )}
    </DescriptionEditorContainer>
  );
};

DescriptionEditor.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isEditing: PropTypes.bool,
  imageDescription: PropTypes.string,
};

export default DescriptionEditor;
