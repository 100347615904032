import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  replaceInUrl,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchClients = (payload) =>
  getRequest(apiEndpoints.clients.getClients, payload);

export const attemptFetchClientsForFilter = (payload) =>
  getRequest(apiEndpoints.clients.getClientsForFilter, payload);

export const attemptCreateClient = (payload) =>
  postRequest(apiEndpoints.clients.createClient, payload);

export const attemptFetchSingleClient = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.clients.getSingleClient, {
      clientId: payload?.clientId,
    })
  );

export const attemptEditSingleClient = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.clients.editClient, {
      clientId: payload?.clientId,
    }),
    payload?.data
  );

export const attemptDeleteClient = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.clients.deleteClient, {
      clientId: payload?.clientId,
    })
  );
