import axios from "axios";
import { JWT_REFRESH_TOKEN, JWT_TOKEN } from "../../constants/localStorage";
import { attachPostRequestListener } from "../../request/index";
import {
  authScopeSetHelper,
  authScopeStringGetHelper,
} from "../../util/helpers/authScopeHelpers";
import { logoutUser } from "../actions/login/loginActions";

const baseURL = process.env.REACT_APP_BASE_API_URL;

let getRefreshToken = (jwtToken, refresh) =>
  axios.post(
    `${baseURL}v1/Users/authenticate/refresh`,
    {
      refreshToken: refresh,
    },
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );

let refreshTokenPromise;

//Interceptor unique name
export const refreshTokenMiddlewareInterceptorName =
  "REFRESH_TOKEN_INTERCEPTOR";

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    attachPostRequestListener((response) => {
      const jwtToken = authScopeStringGetHelper(JWT_TOKEN);
      const refresh = authScopeStringGetHelper(JWT_REFRESH_TOKEN);
      if (response?.response?.status === 401) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = getRefreshToken(jwtToken, refresh).then(
            (token) => {
              refreshTokenPromise = null;
              return token;
            }
          );
        }

        return refreshTokenPromise
          .then((res) => {
            const newToken = res.data;
            authScopeSetHelper(JWT_TOKEN, newToken?.token);
            authScopeSetHelper(JWT_REFRESH_TOKEN, newToken?.refreshToken);
            let originalRequest = response.config;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken?.token;
            originalRequest.headers['Authorization'] = 'Bearer ' + newToken?.token;
            refreshTokenPromise = null;
            return Promise.resolve(axios(originalRequest));
          })
          .catch((e) => {
            refreshTokenPromise = null;
            dispatch(logoutUser());
            return Promise.reject(e);
          });
      }
    });
    next(action);
  };