import React from "react";
import PropTypes from "prop-types";
import DocumentsContent from "../../../components/Documents/DocumentsContent";
import { batch, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchClientsForFilter } from "../../../store/actions/clients/clientsActions";
import { fetchDocumentTypes } from "../../../store/actions/documentTypes/documentTypesActions";

const DocumentsIndexPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    batch(() => {
      dispatch(fetchClientsForFilter({ page: 1, size: 2000 }));
      dispatch(fetchDocumentTypes({ page: 1, size: 2000 }));
    });
  }, []);
  return <DocumentsContent />;
};

DocumentsIndexPage.propTypes = {
  children: PropTypes.node,
};

export default DocumentsIndexPage;
