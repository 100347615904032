import { Box, Button, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../themes";
import ImagePicker from "../../../ImagePicker/ImagePicker";

export const FindingQuestionImagePickerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const FindingQuestionImagePickerLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.labelTextColor};
  /* color: ${selectedTheme.colors.deviceColor}; */
  ${(props) =>
    props?.required &&
    css`
      &::after {
        content: "*";
        color: ${selectedTheme.colors.errorColor};
      }
    `}
`;

export const FindingQuestionImagePickerListContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  border: ${(props) =>
    props?.$hasBorders && `1px solid ${selectedTheme.colors.inputBorder}`};
  border-radius: 8px;
  width: fit-content;
  @media (max-width: 600px) {
    justify-content: space-evenly;
  }
  /* max-width: 320px; */
`;
export const FindingQuestionSingleImagePicker = styled(ImagePicker)`
  flex: 1;
  ${(props) =>
    props?.$hasImages &&
    css`
      /* padding: 8px; */
      width: 120px !important;
      max-width: 120px !important;
      height: 120px !important;
      max-height: 120px !important;
      min-height: 120px !important;
      & > form {
        padding: ${(props) => !props?.image && "8px"};
        border: none;
        width: 120px !important;
      }
      & p {
        text-align: center;
      }
      & * {
        max-width: 120px;
        max-height: 120px;
      }
    `}
  @media (max-width: 600px) {
    align-self: center;
  }
`;

export const FindingQuestionSingleImageWithDescription = styled.div`
  border: 1px ${(props) => (props.borderStyleSolid ? "solid" : "dashed")} ${selectedTheme.colors.inputBorder};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => props?.$imagePicker && !props?.$hasImages ? "fit-content" : "190px"};
`;

export const AddImageDescriptionButton = styled(Button)`
  border: 1px solid ${selectedTheme.colors.inputBorder};
  border-radius: 4px;
  height: 32px;
  margin: 15px 0px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${selectedTheme.colors.gray};
  text-transform: none;
  width: auto;
  max-width: 115px;
`;

export const FindingQuestionSingleImageDescription = styled.div`
  max-width: 120px;
  max-height: 70px;
  width: 120px;
  height: 70px;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;
