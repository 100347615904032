import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "../../util/helpers/colorHelper";
import selectedTheme from "../../themes";
import { ReactComponent as Trash } from "../../assets/images/svg/trash.svg";
import { ReactComponent as Edit } from "../../assets/images/svg/edit.svg";

export const DescriptionEditorContainer = styled(Box)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
`;

export const Description = styled(Typography)`
  padding: 4px;
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 300;
  text-align: center;
  display: -webkit-box;
  max-height: 64px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DescriptionOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) =>
    hexToRGB(selectedTheme.colors.black, props?.$hidden ? 0 : 0.5)};
  z-index: 3;
  overflow: hidden;
  border-radius: 8px;
`;

export const IconButton = styled(Box)`
  cursor: pointer;
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditIcon = styled(Edit)``;
export const TrashIcon = styled(Trash)``;

export const ToolsContainer = styled(Box)`
  display: flex;
  z-index: 4;
  top: 3px;
  right: 3px;
  position: absolute;
  gap: 2px;
`;

export const Tooltip = styled(Box)``;
