import {
  CLEAR_DOCUMENTS,
  SET_DOCUMENTS,
  SET_TOTAL_DOCUMENTS,
  SINGLE_DOCUMENT_SET,
} from "../../actions/documents/documentsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  documents: {
    loaded: false,
    data: [],
  },
  totalDocuments: 0,
  singleDocument: {},
};

export default createReducer(
  {
    [SET_DOCUMENTS]: setDocuments,
    [SINGLE_DOCUMENT_SET]: setSingleDocument,
    [SET_TOTAL_DOCUMENTS]: setTotalDocuments,
    [CLEAR_DOCUMENTS]: clearDocuments,
  },
  initialState
);
function setDocuments(state, { payload }) {
  return {
    ...state,
    documents: payload,
  };
}
function setTotalDocuments(state, { payload }) {
  return {
    ...state,
    totalDocuments: payload,
  };
}
function clearDocuments(state) {
  return {
    ...state,
    documents: initialState.documents,
  };
}
function setSingleDocument(state, { payload }) {
  return {
    ...state,
    singleDocument: payload,
  };
}
