import {
  CLOSE_MODAL,
  SET_MODAL_TYPE,
  TOGGLE_DISTRIBUTION_EMAILS_MODAL,
  TOGGLE_IMAGE_PREVIEW_MODAL,
  TOGGLE_TABLE_PREVIEW_MODAL,
  TOGGLE_SET_MEASURING_INSTRUMENT_MODAL,
  TOGGLE_SET_PASSWORD_MODAL,
  TOGGLE_SET_STANDARD_MODAL,
  TOGGLE_SET_DELETE_MODAL,
  CLOSE_DELETE_MODAL,
  TOGGLE_SET_LOADING_MODAL,
  CLOSE_LOADING_MODAL,
  TOGGLE_CONFIRM_MODAL,
  TOGGLE_SET_SCANNING_OBJECT_MODAL,
  TOGGLE_SEND_MAILS_MODAL,
  TOGGLE_SET_USER_MODAL,
  CLOSE_USER_MODAL,
  TOGGLE_TABLE_MODAL,
  TOGGLE_COPY_FINDING_MODAL,
  TOGGLE_RENAME_SCANNING_SUBJECT_MODAL,
  TOGGLE_COLOR_PICKER_MODAL,
  TOGGLE_RENAME_COLUMNS_MODAL,
  TOGGLE_ADD_FINDING_STAFF_MODAL,
  TOGGLE_SET_FINDING_TYPES_MODAL,
  TOGGLE_SET_ATTENDED_PERSON_MODAL,
  TOGGLE_FINDING_PREVIEW_MODAL,
  TOGGLE_SET_FINDING_TYPE_MODAL,
  TOGGLE_LICENSE_MODAL,
  TOGGLE_DOCUMENT_TYPE_MODAL,
  TOGGLE_INVITE_USER_MODAL,
  TOGGLE_IMPORT_SUBJECT_MODAL,
  TOGGLE_SET_FINDING_QUESTION_IMAGE_DESCRIPTION_MODAL,
} from "../../actions/modal/modalActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  setPasswordModal: false,
  setStandardModal: false,
  setFindingTypeModal: false,
  setDeleteModal: false,
  setMeasuringInstrumentModal: false,
  setScanningObjectModal: false,
  setLoadingModal: false,
  setFindingTypesModal: false,
  distributionEmailsModal: false,
  ImagePreviewModal: false,
  confirmModal: false,
  importSubjectModal: false,
  sendMailsProps: false,
  setUserModal: false,
  tableModal: false,
  copyFindingModal: false,
  renameScanningSubjectModal: false,
  colorPickerModal: false,
  renameColumnsModal: false,
  addFindingStaffModal: false,
  attendedPersonModal: false,
  documentTypeModal: false,
  props: {},
  preventCloseModal: false,
  inviteUserModal: false,
  setFindingQuestionImageDescriptionModal: false,
};

export default createReducer(
  {
    [TOGGLE_SET_PASSWORD_MODAL]: toggleSetPasswordModal,
    [TOGGLE_DISTRIBUTION_EMAILS_MODAL]: toggleDistributionEmailsModal,
    [TOGGLE_SET_STANDARD_MODAL]: toggleSetStandardModal,
    [TOGGLE_SET_FINDING_TYPE_MODAL]: toggleSetFindingTypeModal,
    [TOGGLE_SET_DELETE_MODAL]: toggleSetDeleteModal,
    [TOGGLE_SET_USER_MODAL]: toggleSetUserModal,
    [TOGGLE_SET_MEASURING_INSTRUMENT_MODAL]: toggleSetMeasuringInstrument,
    [TOGGLE_SET_SCANNING_OBJECT_MODAL]: toggleSetScanningObject,
    [TOGGLE_DOCUMENT_TYPE_MODAL]: toggleDocumentTypeModal,
    [TOGGLE_IMAGE_PREVIEW_MODAL]: toggleImagePreviewModal,
    [TOGGLE_TABLE_PREVIEW_MODAL]: toggleTablePreviewModal,
    [TOGGLE_FINDING_PREVIEW_MODAL]: toggleFindingPreviewModal,
    [TOGGLE_IMPORT_SUBJECT_MODAL]: toggleImportSubjectModal,
    [TOGGLE_SET_LOADING_MODAL]: toggleSetLoadingModal,
    [TOGGLE_CONFIRM_MODAL]: toggleConfirmModal,
    [TOGGLE_COLOR_PICKER_MODAL]: toggleColorPickerModal,
    [TOGGLE_SEND_MAILS_MODAL]: toggleSendMailsModal,
    [TOGGLE_TABLE_MODAL]: toggleTableModal,
    [TOGGLE_COPY_FINDING_MODAL]: toggleCopyFindingModal,
    [TOGGLE_RENAME_SCANNING_SUBJECT_MODAL]: toggleRenameScanningSubjectModal,
    [TOGGLE_RENAME_COLUMNS_MODAL]: toggleRenameColumnsModal,
    [TOGGLE_LICENSE_MODAL]: toggleLicenseModal,
    [TOGGLE_INVITE_USER_MODAL]: toggleInviteUserModal,
    [TOGGLE_ADD_FINDING_STAFF_MODAL]: toggleAddFindingStaffModal,
    [TOGGLE_SET_FINDING_TYPES_MODAL]: toggleSetFindingTypesModal,
    [SET_MODAL_TYPE]: setModalType,
    [CLOSE_DELETE_MODAL]: closeDeleteModal,
    [CLOSE_USER_MODAL]: closeUserModal,
    [CLOSE_LOADING_MODAL]: closeLoadingModal,
    [CLOSE_MODAL]: closeModal,
    [TOGGLE_SET_ATTENDED_PERSON_MODAL]: toggleSetAttendedPersonModal,
    [TOGGLE_SET_FINDING_QUESTION_IMAGE_DESCRIPTION_MODAL]: toggleSetFindingQuestionImageDescriptionModal,
  },
  initialState
);
function toggleSetPasswordModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    setPasswordModal: !state.setPasswordModal,
    props: { ...state.props, passwordProps: payload },
  };
}
function toggleDistributionEmailsModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    distributionEmailsModal: !state.setPasswordModal,
    props: { ...state.props, emailProps: payload },
  };
}
function toggleRenameColumnsModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    renameColumnsModal: !state.renameColumnsModal,
    props: { ...state.props, renameColumnsProps: payload },
  };
}
function toggleLicenseModal(state, { payload }) {
  return {
    ...state,
    licenseModal: !state.licenseModal,
    props: { ...state.props, licenseModalProps: payload },
  };
}
function toggleImportSubjectModal(state, { payload }) {
  return {
    ...state,
    importSubjectModal: !state.importSubjectModal,
    props: { ...state.props, importSubjectModalProps: payload },
  };
}
function toggleDocumentTypeModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    documentTypeModal: !state.documentTypeModal,
    props: { ...state.props, documentTypeProps: payload },
  };
}
function toggleSetMeasuringInstrument(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    setMeasuringInstrumentModal: !state.setPasswordModal,
    props: { ...state.props, measuringProps: payload },
  };
}
function toggleSetScanningObject(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    setScanningObjectModal: !state.setScanningObjectModal,
    props: { ...state.props, scanningObjectProps: payload },
  };
}
function toggleColorPickerModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    colorPickerModal: !state.colorPickerModal,
    props: { ...state.props, colorPickerProps: payload },
  };
}
function toggleSetStandardModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    setStandardModal: !state.setStandardModal,
    props: { ...state.props, standardProps: payload },
  };
}
function toggleSetFindingTypeModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    setFindingTypeModal: !state.setFindingTypeModal,
    props: { ...state.props, setFindingTypeProps: payload },
  };
}
function toggleSetDeleteModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    setDeleteModal: !state.setDeleteModal,
    props: { ...state.props, deleteProps: payload },
  };
}
function toggleSetUserModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    setUserModal: !state.setUserModal,
    props: { ...state.props, userProps: payload },
  };
}
function toggleImagePreviewModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    imagePreviewModal: !state.imagePreviewModal,
    props: { ...state.props, imageProps: payload },
  };
}
function toggleFindingPreviewModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    findingPreviewModal: !state.findingPreviewModal,
    props: { ...state.props, findingPreviewProps: payload },
  };
}
function toggleTablePreviewModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    tablePreviewModal: !state.tablePreviewModal,
    props: { ...state.props, tablePreviewProps: payload },
  };
}
function toggleConfirmModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    confirmModal: !state.imagePreviewModal,
    props: { ...state.props, confirmProps: payload },
  };
}
function toggleSendMailsModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    sendMailsModal: !state.sendMailsModal,
    props: { ...state.props, sendMailsProps: payload },
  };
}
function toggleTableModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    tableModal: !state.tableModal,
    props: { ...state.props, tableProps: payload },
  };
}
function toggleCopyFindingModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    copyFindingModal: !state.copyFindingModal,
    props: { ...state.props, copyFindingProps: payload },
  };
}
function toggleRenameScanningSubjectModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    renameScanningSubjectModal: !state.renameScanningSubjectModal,
    props: { ...state.props, renameScanningSubjectProps: payload },
  };
}
function toggleAddFindingStaffModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    addFindingStaffModal: !state.addFindingStaffModal,
    props: { ...state.props, addFindingStaffModal: payload },
  };
}
function toggleSetLoadingModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    setLoadingModal: !state.setLoadingModal,
    props: { ...state.props, loadingProps: payload },
  };
}
function setModalType(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    type: payload,
  };
}
function closeDeleteModal(state) {
  return {
    ...state,
    props: { ...state.props, deleteProps: {} },
    setDeleteModal: false,
  };
}
function closeUserModal(state) {
  return {
    ...state,
    props: { ...state.props, userProps: {} },
    setUserModal: false,
  };
}
function closeLoadingModal(state) {
  return {
    ...state,
    setLoadingModal: false,
  };
}
function closeModal() {
  return initialState;
}

function toggleSetFindingTypesModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    setFindingTypesModal: !state.setFindingTypesModal,
    props: { ...state.props, findingTypesProps: payload },
  };
}

function toggleSetAttendedPersonModal(state, { payload }) {
  return {
    ...state,
    preventCloseModal: payload?.preventCloseModal,
    attendedPersonModal: !state.attendedPersonModal,
    props: { ...state.props, attendedPersonProps: payload },
  };
}

function toggleInviteUserModal(state, { payload }) {
  return {
    ...state,
    inviteUserModal: !state.inviteUserModal,
    props: { ...state.props, inviteUserProps: payload },
  };
}

function toggleSetFindingQuestionImageDescriptionModal(state, { payload }) {
  return {
    ...state,
    setFindingQuestionImageDescriptionModal: !state.setFindingQuestionImageDescriptionModal,
    props: { 
      ...state.props, 
      setFindingQuestionImageDescriptionModalProps: {
        ...payload,
        onDescriptionSubmit: payload.onDescriptionSubmit,
      },
    },
  };
}
