import {
  CLEAR_STUFFS,
  SET_STUFFS,
  SET_TOTAL_STUFFS,
  SINGLE_STUFF_SET,
  REMOVE_STUFF,
  SINGLE_STUFF_CLEAR,
} from "../../actions/stuffs/stuffsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  stuffs: {
    loaded: false,
    data: [],
  },
  totalStuffs: 0,
  singleStuff: {},
};

export default createReducer(
  {
    [SET_STUFFS]: setStuffs,
    [SINGLE_STUFF_SET]: setSingleStuff,
    [SINGLE_STUFF_CLEAR]: clearSingleStuff,
    [SET_TOTAL_STUFFS]: setTotalStuffs,
    [CLEAR_STUFFS]: clearStuffs,
    [REMOVE_STUFF]: removeStuff,
  },
  initialState
);
function setStuffs(state, { payload }) {
  return {
    ...state,
    stuffs: payload,
  };
}
function setTotalStuffs(state, { payload }) {
  return {
    ...state,
    totalStuffs: payload,
  };
}
function clearStuffs(state) {
  return {
    ...state,
    stuffs: initialState.stuffs,
  };
}
function setSingleStuff(state, { payload }) {
  return {
    ...state,
    singleStuff: payload,
  };
}
function clearSingleStuff(state) {
  return {
    ...state,
    singleStuff: initialState.singleStuff,
  };
}

function removeStuff(state, { payload }) {
  return {
    ...state,
    stuffs: {
      data: state.stuffs.data.filter((el) => el.id != payload),
      loaded: true,
    },
    totalStuffs: state.totalStuffs--,
  };
}
